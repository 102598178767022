.nav {
  background-color: $backgroundDefault;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $headerFont;
  padding: 1em;

  li {
    font-size: 1.25em;
  }

  @media (min-width: 500px) {
    padding: 2em;
  }
  .nav__logo {
    font-size: 2em;
    color: $fontSecondary;

    @media (min-width: 550px) {
      font-size: 1.8em;
    }
    @media (max-width: 549px) {
      font-size: 1.4em;
    }

    @media (min-width: 700px) {
      font-size: 2em;
    }

    & a {
      color: $bodyColor;
      text-decoration: none;

      &:hover {
        color: $accentColor;
      }
    }
  }

  .nav__links-container {
    display: flex;
  }

  .nav__link-item + .nav__link-item {
    margin-left: 1em;

    @media (min-width: 550px) {
      margin-left: 2em;
    }
    @media (min-width: 900px) {
      margin-left: 2.5em;
    }
  }

  .nav__link-item a {
    text-decoration: none;
    color: inherit;
    font-size: 1.4em;

    @media (min-width: 550px) {
      font-size: 1.4em;
    }
    @media (max-width: 549px) {
      font-size: 1.2em;
    }

    &:hover {
      background-color: $fontSecondary;
      background-color: transparent;
      color: inherit;
      text-decoration: none;
      transition: background .15s cubic-bezier(.33,.66,.66,1);
      border-bottom: 4px solid $fontSecondary;
      box-shadow: inset 0 -3px 0 $fontSecondary;
      overflow-wrap: break-word;
      word-break: break-word;
      word-wrap: break-word;
    }
  }

  .nav__link-item--active {
    color: $accentColor;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      height: 20px;
      position: absolute;
      display: block;
      left: 0;
      top: 1.5em;
      z-index: 1;
    }
  }
}
